

@media all and (max-width: 1500px) {
  #heroSection{padding:50px 0 0px;}
  .sliderMain .slick-track img{height:650px;}
  .sliderMain .slick-track video{height:650px; object-fit: cover;}
}


@media all and (max-width: 1200px) {
  #heroSection .transparentBg{bottom:-100px;}
  .sliderMain .slick-track img{height:500px;}
  .sliderMain .slick-track video{height:500px; object-fit: cover;}
}


@media all and (max-width: 1199px) {
  .houseRenovation h4{font-size: 25px;}
  .projects:after {
    /* width: 84%; */
    left: 20%;
  }
  .claenderDesign {
    padding: 4px 8px;
    max-width: 125px;
  }

  .freeImage {
    max-width: 100%;
    max-height: none;
  }

  #newsLetter h4 {
    padding: 10px 0;
  }
  .coupleUsingMobile {
    margin-top: -100px;
  }
  #newsLetter .main {
    margin-top: 15px;
  }

  .padding-footer {
    padding: 50px;
  }
  .padding-footerLast {
    padding: 0 50px 20px;
  }

  .poliginResp {
    position: absolute;
    top: -150px;
    left: 0;
  }

}
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
      .SafeandSecured {
        /* position: absolute; */
        background: linear-gradient( 90deg, rgba(1, 26, 91, 0.7) 0.09%, rgba(14, 37, 98, 0.35) 99.84% );
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        z-index: 1;

      }
      .CommitedShariaImage{
        bottom: 0;
        right: 80px;
      }
      .logoFigCaption::after{
        content: "";
        background-color: #e6e6e6;
        position: absolute;
        width: 1px;
        height: 75px;
        top: 0;
        left: -5px;
        right: 0;
      }
      
      .teslaName {
        max-width: 229px;
      }
      .cardBodyHeight {
         max-width: 308px; 
      }
      .coreTeamMemberPics{

      }
      .coreTeamMemberPics{
        font-size: 20px;
      }
      .ChairPersonName p {
        font-size: 18px;
      }
      #heroSection11 h4 {
        font-size: 35px !important;
      }
      .sendMessageBox{
        margin-left: 10px;
      }
      .memberIDSection{
        flex-direction: column;
      }
      .customTab{
        /* flex-direction: column; */
        justify-content: left;
      }
      .Detailbox{
        flex-direction: column;
        text-align: center;
      }
      .Detailbox1{
        flex-direction: column;
        text-align: center;
      }
     
      }

@media all and (max-width: 991px) {
  .tab-content section .col-lg-9, .tab-content section .col-md-9{padding-right: 15px;}
  #heroSection{padding:50px 0 150px;}
  #heroSection .transparentBg{bottom:-80px; overflow:hidden;}
  #heroSection .transparentBg img{max-width:none; width:150%; left:-50%; position:relative; height:500px;}

  #heroSection11.whitePaperBnr{background-position-x:center;}

  #teamBanner{height:700px;}
  #teamBanner p{margin-top:30px;}
  #teamBanner .teamBanner1{top:auto; position:relative;}

  .sliderMain .slick-track img{height:400px;object-fit: cover;}

  ul.invesTab {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 5px;
  }
  ul.invesTab li {
    flex: 0 0 60%;
    padding-right: 15px;
  }

  .freeImageTop {
    margin-top: 20px;
  }

  .ribaIcon {
    max-width: 100%;
  }

  .ribaIcon p.offersPara {
    padding-left: 10px;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  /* .cardBodyHeight{height:460px;} */
  .projects:after{
    width:58%;
    left: 36%;
  }

  .coupleUsingMobile{margin-top:50px;}
  #newsLetter .paddingLeft{padding-top:50px;}

  .privacypolicy{margin-top:0px;}
  .padding-footer{margin:0;}

  .email{margin-top:40px;}
  .copyright{margin-top:50px;}

  #heroSection9 .banrBox{display:block;}

  .houseRenovationTop{padding-top:50px;}

  .socalBox{margin-top:50px;}
  .socalBox h3{margin-bottom:30px;}

  #heroSection9 {
    background:url(/public/Image/contactUspageMobile.png) no-repeat center center;
    background-position: right bottom;
    padding-top:60px;
  }
  .sendMessageBox {
    padding:10px;
    margin-bottom: 15px;
    box-shadow: 0 0 5px #CCC;
  }
  
.sendMessageButton{padding:25px 0;}
.sendMessageBox a.btn{margin-top:10px;}
.manUsingLaptop{background-position:bottom center; background-size:contain; padding-bottom:100%;}
#faqBanner{background-image:url(/public/Image/faqBannerMobile.png); background-position:center bottom; height:750px;}
#faqBanner .privacyBanner1{top:150px;}
.howToInvest .inviteVideo iframe{width:100%; height:280px;}
#heroSection9.meetMabrookBnr{background-size:cover; height:950px;}

.howto{flex-wrap:wrap;}  
.howtoInvestPara{padding-bottom:20px; text-align:left;}
.clanderImagePara{width:100%; display:flex; justify-content:space-between; padding:0 0 10px; border-bottom:1px solid #dfc7d1;}
.clanderImagePara::after{display:none;}
.paraTextoverview{padding:20px 0 0;}
.arabManUsingMobile{margin-top:0;}
/* .slick-slide{padding:0px 10px 50px;} */
.login-section{padding:20px 20px 0;}
.pop_content{padding:30px;}
.pop-bg{padding-top:0px;}
.close-button{position:absolute; top:36px; right:38px;}
.subscribeNewsLette{margin:0px; text-align:center;}
.socialMediaIcon{margin:0; text-align:center;}
.login-section{padding-right:10px;}
.arabManUsingMobile{margin-top:0;}

.reviewList li{font-size: 13px;}
.selficaptureButton{flex-wrap: wrap;}
.selficaptureButton .kycInfoDetail{flex: 0 0 100%;}

.sendMessage .slick-dots{
margin-bottom: 12px;
}
.cardBodyHeight{
  max-width: unset;
}
.termsConditionSignInpage{
  align-items: baseline;
}
#heroSection{
  padding: 50px 0 26px;
}

.SafeandSecured{
  top: 61px;
  left: 0;
  width: 100%;
}
.familyPhoto{
  top: 31px;
}
.CommitedShariaImage {
  right: 20px;
}
.CommitedShariaHeading {
  max-width: 330px;
}
.col1:after{
  right: -8px;
}
.logoFigCaption{
  /* flex-direction: column; */
}

.totalShareAmount{
padding: 10px;
}
.Detailbox{
  flex-direction: column;
}
/* .Detailbox1{
  flex-direction: column;
} */
.welcomebackCountinue{
flex-direction: column;
}
.selficaptureButton{
  justify-content: left;
} 
.kycDisplaySection{
  flex-direction: unset !important;
  height: 23rem;
}
.navBarCerPara::after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #003bd6;
  width: 65%;
  top: 9px;
  margin-left: 10px;
}
.companywithPartner{
flex-flow: column;
}
.logoFigCaption{
justify-content: flex-start;
}
.popupSectionDolarIcon-deta{
  right: 0 !important;
}
.myInputSearchImage{
  font-size: 19px;
}
}

@media all and (max-width: 767px) {

  /* .buttonView{width: 60px;} */
  .imageWithQuestion{flex: 0 0 70%;}
  .tabOverView p{font-size: 13px;line-height: normal;}
  .imageCounting img{width:20px;}
  .imageCountingNumber div img{width: 25px;}
  .faqNumber{font-size: 15px;}
  .businessReviewForm{padding: 50px 0 50px 0px;}
  .PersonalInformationsSection{width: 100%; text-align: center;max-width: inherit; margin: 0;}

 
.col1:after,
.col2:after{display:none;}

.freeImageTop {margin-top: 50px;}

ul.invesTab li {flex: 0 0 80%;}

.cardBodyHeight {margin-bottom: 104px;}
/* .cardBodyHeight {height: auto; margin-bottom: 20px;} */
.howto {display: block;text-align: center;}
.howto .btn__theme {
  /* margin: 10px auto 0; */
  margin: 0;
}


.bannerThum .slick-slider .slick-prev{left:0px; top: 55px;}
.bannerThum .slick-slider .slick-next{
  right:0px;
  top: 55px;
}

.certifieds {padding: 40px;}

.padding-footer{
  padding: 60px 15px 1px;
}
.email {flex-wrap: wrap;}

.viewMore{justify-content:center; margin-top:15px;}

.browseProjectBnr{padding:100px 0; background:none;}

.howOuld{position:relative; top:0px; margin-bottom:30px;}

.howOuld > .slick-dots{
  margin-bottom: 36px !important;
}
#heroSection9.meetMabrookBnr{height:1000px;}

#heroSection11.whitePaperBnr{background-position-x:70%;}

.inputSearchBorder{border-right:0px; border-bottom:1px solid #dbdee9;}

.dropdown__filter{padding:12px 20px;}
.dropdown__options-filter{max-width:none; margin-top:0; border-radius:31px;}

.searchPoint{padding:20px;}
.searchPoint .searchItem .inputSearchBorder{margin-bottom:35px; height:70px; border-bottom:2px solid #DBDEE9; background-position:5px 20px; font-size: 20px;}
.searchPoint .form-select{height:50px; border-radius:50px; border:1px solid #DBDEE9; padding:0 30px;}

.questionsPara {padding:0px;}

.mabrookSection .nav-tabs{ flex-wrap: nowrap; overflow-x: scroll; white-space: nowrap; }

.manUsingLaptop{background:url(/public/Image/manUsingLaptopResponsive.png) no-repeat center bottom; position:relative; padding-bottom:500px;}
.manUsingLaptop::after{content:""; position:absolute; height:100%; width:100%; top:0; left:0; z-index:-1;
	background:linear-gradient( -80.37deg, rgba(0, 148, 255, 0.2) 11.06%, rgba(217, 217, 217, 0) 102.87%, rgba(217, 217, 217, 0) 102.87%, rgba(255, 255, 255, 0.2) 102.87% );}

.poliginResp{left:0px;}

.dollarImage{margin-top:30px; justify-content:center;}

.dollarImage{margin-top:30px; justify-content:center;}

.login-section {
	padding:20px 20px 0;
}

.pop_content{padding:30px;}
.pop-bg{padding-top:0px;}
.close-button{position:absolute; top:36px; right:38px;}
.form-control{margin-bottom: 0px;}
.totalTitle p{font-size: 14px;}

.listDate li{margin-bottom: 10px;}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.personalInfo li{
flex: 0 0 50% !important;
}

.transApproved .modal-body {
  padding: 0 10px 20px !important;
}
.personalInfo li span {display: block;  word-break: break-all;}
.personalInfo li{font-size: 13px !important;}

.navigation ul.menubarSlide{overflow-y:scroll; height:100vh; }
.searchButton{margin-top:0px; margin-bottom:20px;}
.exportSendMessage :nth-child(2){margin-top: 10px;}
.certi{padding: unset !important;}
.learnMore_ShariaCompialnce{margin-top: 20px;}
.howto h4 {line-height: 48px;}
.claenderDesign{text-align: center;}
.detailTabDashboard ul.tabBox li button{white-space: nowrap;}
.detailTabDashboard ul.tabBox{overflow-x: auto;overflow-y: hidden;flex-wrap: nowrap;}
.detailTabDashboard .tab-content{padding-top: 0px !important;}
.card.typeFile{min-height: 180px;}
/* .addBannerSize{
  min-height: 200px;
} */
.downLoadAllfiles{flex-direction: column;line-height: 43px;}
.dateTeam{line-height: 14px;}
/* .token{padding: 0px;} */
.checkPriviewButtonDashboard{flex-flow: column; row-gap: 10px;}
.documentsDashBoard{height: 200px;}
.imagePriview{margin-top: 20px;text-align: center;}
.updateBox2{padding: 10px;}
.addbannerpictureMobile{padding-top: 20px;}
.table th, .table td {
  border-top: 0px solid #dee2e6 !important;
  white-space: nowrap;
}
.listDate{
  white-space: nowrap;
  max-width: 270px;
  overflow: scroll;
}
.myDocuments{flex-direction: column;padding-bottom: 20px;}
.overTitle{margin-bottom: 30px !important;}
.TotalInvestmentValue{flex-direction: column; gap: 15px;}
.TransactionHistory{flex-direction: column;margin-top: 20px;}
.MyInvestments{flex-direction: column;}
.earnList li{border-bottom: 1px solid #5f718d;}
.earnImage{overflow-x: scroll;}
.walletTab{overflow-x: scroll;}
.addNewAccount{width: unset !important;}
.listBank p, .listBank a{color: #001a5c;font-weight: 400 !important;}
.listBank{flex-direction: column;line-height: 25px;font-weight: 400 !important;}
.avaTitle h1{font-size: 35px !important;}
.avaTitle{padding: 30px 0 !important;}
.downloadDashBoard{margin: 10px 0 !important;}
.walletTab{padding-bottom:12px !important; white-space:nowrap; overflow-x:scroll; margin-top:16px;}
.tokenHistory{flex-direction:column;}
.listOverview li{align-items:center;}
.overMain{background-color:transparent !important; padding: 0px;}
.editBtnDetails{ transform:unset !important;}
.socialTitle span {position:absolute; left:18px; bottom:0; font-size:20px; color:#1da1f2; top:3px; right:0;}
.memberIDSection{flex-direction:column; border-bottom:1px solid #eee; line-height:25px; align-items:unset !important;}
.setingDetail{list-style-type:none; flex-direction:column;}
.userContent{padding:20px 0px;}
.memberIDSectionTop{align-items: unset !important;}
.lastLogin{display:flex; align-items:baseline; justify-content:space-between; border-bottom:1px solid #eee; padding:10px 0px;}
.lastLoginresponsive{
  flex-flow: column;
}
.setingDetail li figure, .setingDetail li figure img{
  margin: 0 auto;
}
.customTab{border-bottom:1px solid #efefef; padding-bottom:25px; display:flex; list-style-type:none; white-space:nowrap; overflow-x:scroll;}
.traHistory{margin-top:0px; padding-top:0px;}
#MyDocuments{padding-top:10px;}

.userContent h3{font-size:15px;}
.wrapper{width:unset !important;}
.wrapper .option{width:unset !important;}

.Verify-otp-div input[type="text"] {
  width: 44px;
  margin: 0px 0px;
  text-align: center;
  font-size: 22px !important;
  font-weight: 350;
}
.otpList li input {
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: unset !important;
}
/* .otpList li {margin-right: 12px !important;} */

.userProfile > div {
  margin-right: 10px !important;
}
.selfEmployed{
  border-bottom: 1px solid #5f718d;
    padding: 6px 10px 10px 10px;
}
.sliderMain .slick-track img{
  object-fit: cover;
}
.sliderMain .slick-track video{
  object-fit: cover;
}
.reviewList{
flex-direction: column;
}
.reviewList li{
  border-bottom: 1px solid #5f718d40;
  padding-bottom: 6px;
}
.houseRenoTitle ul{
display: flow !important;
}
.walletBalance{
display: unset !important;
}
.houseRenoTitle{
  padding: 10px;
}
.reviewList{
border-bottom: unset;
}
.AddbalancePaid{
  flex-direction: column;
  padding-bottom: 20px;
  row-gap: 10px;
}
.mobilebanner{
  min-height: 186px;
  object-fit: fill;
  max-width: unset;
}
#newsLetter h4{
line-height: 35px !important;
}
.whitePaperName {
  flex-direction: column;
  line-height: 90px;
}
.main span{
  line-height: 20px !important;
}
.selectOTP{
margin-bottom: 10px;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin-top: -40px;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.synqSection{
flex-direction: column;
}
.lastRefresh{
  padding-bottom: 20px;
}
.ModalSectionOnMobile{
  flex-direction: column;
}
.pdfFileSection{
  display: flex;
  flex-direction: column;
}
.seprationBitweencolumn{
display: grid;
flex: unset !important;
grid-template-columns: unset !important
}
.checkedItem {
flex-direction: column;
}
.customTab {
flex-flow: nowrap;
}
.mAabrookTokenPopup{
  /* margin-left: -33px;
  margin-top: -143px; */
}
.popupSectionDolarIcon-deta {
  right: -59%;
}
.popupSectionDolarIcon-details {
  left: -97%;
}
.logoFigCaption::after {
  left: -4%;
}
.totalTransection{
  flex-direction: column !important;
}
.coreTeamMemberPics {
flex-direction: column;
padding: 5px;
}
.companywithPartner{
flex-direction: column;
}
.responsive{
  height: 281px;
}
.cardBodyHeight{
height: 509px;
max-width: unset;
}
.RaiseFundIcon{
  margin-top: 10px;
}
.teslaName{
max-width: unset;
}
.CommitedSharia{
height: 500px;
}
.CommitedShariaImage {
  right: 30px;
  bottom: 0;
}
.SafeandSecured{
  top: 150px;
  left: 0;
  right: 0;
}
.CommitedShariaImage img{
  max-width: 200px;
  
}
.ChairPersonNameDesigNationMobileVersion{
  flex-direction: column;
}
.mobileResponsive{
  flex-direction: column;
  
}
.clanderImagePara{
flex-direction: column;
}
.reviewList li{font-size: 13px;}
.lastUploadImageSection{
  display: flex;
  justify-content: right;
}
.react-datepicker-wrapper{
  padding-bottom: 10px !important;
}
.socialMedia{
flex-direction: column;
gap: 8px !important;
}
.wrongPassComment{
  margin-left: 15px;
}
.tab-content section .col-lg-9, .tab-content section .col-md-9 {
  padding: 21px !important;
}
.completedForm{
  flex-direction: column;
}
.welcomebackCountinue{
  flex-direction: column;
}
.clockwithtimer{
justify-content: left;
padding-top: 10px;
}
.tickcircless{
  justify-content: space-between;
}
.HalalDescriptionSection{
  flex-direction: column;
}
.HalalDescription {
  line-height: 57px;
}
.verticalLines img{
  display: none;
}
.HowtoInvestVideoExplainerSection {
  margin-bottom: 15px;
}
.saveCloseButton{
  margin-top: 20px;
  text-align: left;
}
.FinancialDocuments{
width: 31%;
}
.imageDetailSection{
max-width: unset;
}
.startRaiseCapitalTop{
margin-top: 15px;
}
.browseprojects{
  margin-top: 20px;
}
.users-details-section ul{
  margin: 0 149px 45px;
}
.users-details-section ul li:nth-child(1){
  margin-left: -149px;
}
.users-details-section ul li:nth-child(2){
  margin-left: 150px;
}
.HowtoInvestVideoExplainer h3 {
font-size: 20px;
line-height: 21px;
}
.HowtoInvestVideoExplainer P {
  font-size: 17px;
}
.HowtoInvestVideoExplainer H6 {
  font-size: 14px;
}
.howToInvest .howToInvestCont{
  margin-top: 0px;
}
.Numbercircle::after{
  height: 120px;
}
.generalInformation{
  margin-left: unset;
  margin-top: 20px;
}
.generalInformationDetail{
  align-items: flex-start;
  flex-direction: column;
  row-gap: 15px;
}
.generalInformationDetailMobile{
  display: flex;
  justify-content: space-between;
}
.AboutTheInvestor{
  flex-direction: column;
}
.userProfilePictureedit {
  margin-top: -71px;
  margin-left: 32px;
}
.userProfilePictureedit img {
  max-width: 50px;
  height: 50px;
}
.editIcon img{
  max-width: 25px;
  height: 25px;
}
.editIcon{
  bottom: 0px;
  right: -6px;
}
.stockCorporateCompany{
  flex-direction: column;
  row-gap: 11px;
}
.Detailbox{
  flex-direction: row;
}
.howItWorks{
  margin-left: 14px;
}
.bannerThum .slick-track .slick-current::after{
  margin: 0px 126px;
}
.yourkycComplete{
  margin-top: 10px;
  font-weight: 400;
}
.nextpreviusButtonpoup{
  flex-direction: column;
  row-gap: 10px;
}
.viewMoreAccountType{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.changeAccountType img{
  width: 100%;
  margin: 0 auto;
}
.leftSideArrowButton{
  width: 100%;
}
.viewMoreAccountType button{
margin-right: unset;
}
.letsgettogehterBox{
flex-direction: column;
}
.letsgettogehterBox img{
 max-width: unset;
}
.login-image img{
  max-width: 100%;
}
.Detailbox::after{
display: none;
}
.documentCard{
  row-gap: 10px;
}
.kyc_created-date{
flex-direction: column;
gap: 10px;
}
.kycDisplaySection{
  height: 470px;
  justify-content: unset;
}
.RibaFreeFlag{
max-width: 202px;
}
.familyPhoto{
  top: 121px;
}
/* .show.userDashboard .leftContainer{
  margin-left: -90px;
} */
/* .show.userDashboard{
  padding-left: unset !important;
}
.show.userDashboard .dashboard{
  padding-left: unset !important;
} */

.show.userDashboard .leftContainer:hover {
  width: auto;
}

.show.userDashboard .leftContainer:hover .title {
  display: none !important;
}

.show.userDashboard .investorBoard{
padding-left: 10px !important;
}
.show.userDashboard .leftContainer{
  width: unset !important;
  margin-left: -50px;
}
.SignUptoMabrook{
  align-items: baseline;
  text-align: left;
}
.show.userDashboard{
  padding-left: unset !important;
}
.logIn a{
  padding: 3px 12px 6px;
  border: 0px solid #f5f7fb;
  font-weight: 300;
}
.logIn a:hover{
  background-color: #0094ff;
}
.signIn a {
  padding: 3px 12px 6px;
  border: 0px solid #fff;
  font-weight: 300;
}
header .crtmNevbar li + li{
  margin-left: 7px;
}
.updateBox2 img{
  max-width: 500px;
  width: 100%;
}
.kycDescriptionImage{
    bottom: -16px; 
    right: 0px;
    /* right: -31px; */
}
.img-fluid-investorProfile {
  width: 372px;
  height: 225px;
}
header .userLogin .dropdown .dropdown-menu {
  margin-left: unset;
  margin-right: -21px;  
}
.kyc_button button{
  margin-top: 10px;
}
.frontBack{
  gap: 20px;
}
.kyc_button{
  display: flex;
  flex-flow: wrap;
}
.incomeOption{
  width: 100%;
  text-align: left;
}
.customeButton{
  width: 100%;
}
.frontBackKYC{
  gap: 20px;
}
.ttlHead{
  margin-top: 10px;
}
.nav_2{
  padding: 0px;
}
.customeBasicInformation{
  margin-top: 0px;
}
.NotificationList {
  flex-flow: column;
  gap: 20px;
}
.customSendLater{
  gap: 10px;
}
.customeAddNewNotification{
  place-self: flex-end;
  flex-flow: column;
  gap: 10px;
  text-align: end;
}
.youtubeLink{
  height: unset;
}
.header .logo img{
  max-height: 35px;
  margin-right: 0px;
}
.newDocMobileResponsive{
  gap: 10px;
}
.docomentTabResponsive{
  place-content: center;
}
.accountType{
  gap: 10px;
}
.chooseLangUage{
  display: none;
}
.login.profileLogoIcon{
  display: none;
}
.messageBox{
right: 0px !important;
}
.notification_areaBox{
  max-width: 350px;
}
.newmsglist p {
  width: 220px !important;
}
.customPagination{
  flex-flow: column;
  display: flex;
}
.girlsWithIpad img{
  margin-bottom: -44px;
}
.containerTimeLine.left{
  left: unset !important
}
.containerTimeLine{
  max-width: 169px !important;
}
.customeellipses{
  padding-left: 8px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blogDetail:after {

  width: 100%;
  height: 100%;
  bottom: 0 !important;
  left: 0;
  z-index: 2;
  background: linear-gradient(360deg, #0094FF 1.39%, rgba(0, 148, 255, 0) 64.48%), linear-gradient(360deg, #001A5C 0%, rgba(0, 26, 92, 0) 64.83%);
}
.custome-blog_mobile{
  margin-top: 170px;
}
.custome-document_mgt{
  justify-content: space-around;
}

}



@media all and (max-width: 575px) {
#heroSection .transparentBg img {
    width: 158%;
    left: -57%;
    height: 350px;
}
#heroSection h4 {
    font-size: 40px;
    line-height:50px;
}
#heroSection p.weAartner {
    font-size: 16px;
    line-height: 30px;
    margin-top: 25px;
}

#heroSection {
   
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding: 50px 0 100px;
    background-position: center;
}

#heroSection::after{display:none;}

  /* #heroSection::before {
    content: "";
    background: url(../public/Image/cloud2.png) no-repeat center left;
    background-size: 100% 100%;
    position: absolute;
    height: 250px;
    width: 110%;
    bottom: 0px;
    right: 0px;
} */


#heroSection11.blogPage{display:block; background-size:cover; height:500px; background-color:#E2F3FD;}
#heroSection11.blogPage h4{font-size:40px;}
#heroSection11.blogPage .blogBanrCont{padding:50px 0;}
#heroSection11.blogPage{display:block; height:750px; background-size:138%; background-color:#C3E3F8; background-position-x:right; background-position-y:bottom;}

#heroSection9 h4{font-size:40px;}

#heroSection11.whitePaperBnr{display:block; height:750px; background-size:138%; background-color:#C3E3F8; background-position-x:right; background-position-y:bottom; background-repeat:no-repeat;}
#heroSection11.whitePaperBnr .blogBanrCont{padding:50px 0; margin:0;}
#heroSection11 h4{font-size:40px;}

.privacyBanner1{position:relative; top:auto; padding:25px 0;}
#privacyBanner{height:550px; background-position-x:90%; background-position-y:bottom; background-size:150%; background-color:#EAF5FD;}

#teamBanner{height:680px; background-size:150%; background-position:-220px bottom; background-color:#CEE9FC;}
#teamBanner p{margin-top:20px;}
  
.fahadCEO{display:flex;}

.slide img{width:100%; margin-bottom:0px;}

.navBarCerPara:after{width:75%;}

#newsLetter{padding-top:0;}

#faqBanner {
	/* background-image: url(http://localhost:3000/static/media/faqBannerMobile.affa76d4773c9e8b0823.png);
	background-position: center bottom;
	height: 750px !important; */
	background-size: contain;
}

#heroSection10.howToInvest
    {
  /* background-image:url(/public/Image/videoSectionResponsive.png); */
  height: auto;
  background-position: center bottom;
  background-size: contain;
  padding-top:30px;
}


.howToInvest .btn__theme {
	margin-top: 550px;
	margin-bottom: 50px;
}

.poliginResp{
      scale: 0.8;
      margin: 0 auto;
      max-width: 179px;
      /* max-width: 269px; */
}


.subscribeNewsInputClass{
  border:1px solid #001a5c;
  border-radius:30px;
  padding-left:15px;
  padding-right: 90px;
}

.detailTab h3{font-size:20px;}
.briefPresention{font-size:12px;}
.briefFolder img{max-width:80px;}
}

@media all and (max-width: 480px) {

  #heroSection .transparentBg img{height:auto;}
  #heroSection .transparentBg{bottom:-60px;}
  #heroSection{padding: 50px 0;}

.raisefund a{width:100%; text-align:center;}
  
/* #heroSection::before{height:200px; bottom:10px;} */

#heroSection9.meetMabrookBnr{height:840px;}

.howToInvest .btn__theme{margin-top:450px; margin-bottom:30px;}
#heroSection10 h4{line-height:40px;}

#heroSection11.blogPage{height:650px;}

#privacyBanner{height:450px;}

#teamBanner{height:500px; background-position:-150px bottom;}

#faqBanner{height:500px;}
#faqBanner .privacyBanner1{top:auto; margin-top:35px;}

.subScribeBox,
.subScribeBox form{width:100%;}
.inputNewsLetter{width:100%;}

.users-details-section{padding-bottom:100px;}

.certifieds{background-image:url(../public/Image/shariaBoard-2.png); padding:50px 40px 400px;}

.weAre{font-size:38px; margin-top:40px; line-height:50px;}

.poliginResp{scale:0.5;}
.circle-container{width:30em; height:30em;}
/* .cardSliderToFix{padding: 0 10px 50px;} */
.briefPresentionTop{padding:10px;}
.briefFolder img{max-width:50px;}
}
#uncontrolled-tab-example-tab-1{
border-radius: 20px;
}
#uncontrolled-tab-example-tab-2{
  border-radius: 20px;
  }
#uncontrolled-tab-example-tab-3{
  border-radius: 20px;
}
#uncontrolled-tab-example-tab-4{
  border-radius: 20px;
  }
  
    
  
